import Vue from 'vue';
import App from './App';
import { router } from './router';
import 'amfe-flexible';

// vant 常用组件 按需引入
import {
  Tab, Tabs, Toast, Popup, DatetimePicker, Area,
  Icon, Uploader, Picker, List, Rate, Field, Tag, Collapse, CollapseItem ,
  Button, Image, Grid, GridItem, RadioGroup, Radio,
  Loading, Cell, CellGroup, NoticeBar, Checkbox, CheckboxGroup,
  Progress, ActionSheet, Dialog , Swipe, SwipeItem , Sticky , Col , Row ,Form 
} from 'vant'


import Vue2TouchEvents from 'vue2-touch-events'
import axios from 'axios';




Vue.use(Vue2TouchEvents , {
  longTapTimeInterval : 200 , 
})
Vue.prototype.$axios = axios; // 全局注册，使用方法为:this.$axios

Vue.use(Tab).use(Tabs).use(Toast).use(Popup).use(DatetimePicker).use(Area)
  .use(Icon).use(Uploader).use(Picker).use(List).use(Rate).use(Field).use(Tag).use(Collapse).use(CollapseItem)
  .use(Button).use(Image).use(Grid).use(GridItem).use(RadioGroup).use(Radio)
  .use(Loading).use(Cell).use(CellGroup).use(NoticeBar).use(Checkbox).use(CheckboxGroup)
  .use(Progress).use(ActionSheet).use(Dialog).use(Swipe).use(SwipeItem).use(Sticky).use(Col).use(Row).use(Form)


new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
